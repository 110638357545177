/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import { useGlobalContext } from "helpers/useGlobalContext";
import { useRouter } from "next/router";
import { useEffect } from "react";

export default function Index() {
  const router = useRouter();
  const { loading, user } = useGlobalContext();
  useEffect(() => {
    if (!loading) {
      if (user) {
        router.push("/admin/dashboard");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, user]);
  return <></>;
}
